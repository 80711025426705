import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useWatchUserActions } from '../../hooks/use-watch-user-actions';
import { TIMERS } from '../../constants/timers';
import { LOCAL_STORAGE } from '../../constants/local-storage';
import { Paths } from '../../routes/paths-enum';
import { useLogout } from '../../hooks/use-logout';

export const ProtectedRoute = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const { isLogout } = useWatchUserActions();

  const { logOut } = useLogout();

  const token = sessionStorage.getItem(LOCAL_STORAGE.isAuthorized);
  // const token = cookieParser?.[COOKIE_STORAGE.accessToken];

  useEffect(() => {
    const lastActivityTime = sessionStorage.getItem(LOCAL_STORAGE.userLastActivityTime);

    if (lastActivityTime && Date.now() - Number(lastActivityTime) >= TIMERS.LAST_ACTIVE) {
      logOut();
    } else sessionStorage.setItem(LOCAL_STORAGE.userLastActivityTime, String(Date.now()));
  }, [logOut]);

  useEffect(() => {
    if (isLogout) {
      setIsVisible(false);
      logOut();
    }
  }, [isLogout, logOut]);

  useEffect(() => {
    const checkToken = setTimeout(() => {
      if (!token) {
        navigate(Paths.MAIN, { replace: true });
      }
    }, 0);

    return () => clearTimeout(checkToken);
  }, [navigate, token]);

  return token && isVisible && <Outlet />;
};
