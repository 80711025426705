import { createReduxHistoryContext, RouterState } from 'redux-first-history';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { EndpointDefinitions } from '@reduxjs/toolkit/dist/query';
import { CombinedState } from '@reduxjs/toolkit/dist/query/core/apiState';
import { createBrowserHistory } from 'history';

import { popupAlertsMiddleware } from './middleware/popup-alerts-middleware';
import appReducer, { AppState } from './modules/app';
import { apiSlice } from './services';

const isProduction = false;

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  savePreviousLocations: 2,
});

export type ApplicationState = Readonly<{
  app: AppState;
  api: CombinedState<EndpointDefinitions, never, 'api'>;
  router: RouterState;
}>;

const rootReducer = combineReducers<ApplicationState>({
  app: appReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  router: routerReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware as any, routerMiddleware, popupAlertsMiddleware),
  devTools: !isProduction,
});

export const history = createReduxHistory(store);
