import { AlertMessage } from '../../constants/alert-message';
import { HttpStatus } from '../../constants/http-status';
import { ApiErrorResponse } from '../../types/api';
import { CredentialsType } from '../../types/credentials';
import { ApiEndpoints } from '../constants/api';
import { EndpointQueryNames } from '../constants/api-group-names';
import { transformErrorWithAlert } from '../helpers/transform-error-with-alert';
import { setAppLoader } from '../modules/app';
import { ErrorCode } from '../../constants/error-code';
import { LOCAL_STORAGE } from '../../constants/local-storage';
import { setTokensToCookie } from '../../utils/cookie-parser';
import { getPushToken, setNativeToken } from '../../hooks/use-get-native-info';
import { history } from '../configure-store';
import { Paths } from '../../routes/paths-enum';
import { requestProcessing } from '../helpers/request-processing';
import { LogOutType, PushTokenType } from '../../types/auth';

import { apiSlice } from '.';

export const authExtendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<void, CredentialsType>({
      query: ({ phone, password }) => ({
        url: ApiEndpoints.LOGIN,
        endpoint: EndpointQueryNames.DEV_MAIN,
        method: 'POST',
        // apiGroupName: ApiGroupNames.AUTH,
        // name: EndpointNames.LOGIN,
        body: new URLSearchParams({
          username: phone,
          password,
        }),
      }),
      extraOptions: { auth: true },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(setAppLoader(true));
          await queryFulfilled;
          dispatch(setAppLoader(false));
        } catch {
          dispatch(setAppLoader(false));
        }
      },

      transformResponse: (response: any, _, args) => {
        const { nextStep } = args;

        if (
          !localStorage.getItem(LOCAL_STORAGE.savedPushToken) ||
          localStorage.getItem(LOCAL_STORAGE.savedPushToken) === 'false'
        ) {
          getPushToken();
        }

        if (nextStep) {
          requestProcessing({ nextStep });
        } else {
          history.push(Paths.HOME);
        }

        return {
          ...response,
        };
      },

      transformErrorResponse: (response: ApiErrorResponse) => {
        const { data: { code } = {}, status } = response;

        if (status === HttpStatus.BAD_REQUEST) {
          return transformErrorWithAlert({
            response,
            message: AlertMessage.BAD_REQUEST,
          });
        }
        if (
          status === HttpStatus.REGISTRATION_PROBLEM &&
          code === ErrorCode.WRONG_PASSWORD_OR_LOGIN
        ) {
          return transformErrorWithAlert({
            response,
            message: AlertMessage.ERROR_LOGIN,
          });
        }

        return transformErrorWithAlert({ response, message: AlertMessage.ERROR_OTHER });
      },
    }),
    refreshPIN: builder.mutation<
      any,
      { token: string; onSuccess?: (v?: any) => void; onError?: (v?: any) => void }
    >({
      query: ({ token }) => ({
        url: ApiEndpoints.REFRESH_TOKEN,
        endpoint: EndpointQueryNames.DEV_MAIN,
        method: 'POST',
        body: new URLSearchParams({
          refreshToken: token,
        }),
      }),
      transformResponse: (response: any, _, args) => {
        const { onSuccess = () => {} } = { ...args };
        const expireMs = (response?.refreshExpiresIn || 1) * 1000;
        const timer = Date.now() + expireMs;

        setTokensToCookie(response);
        sessionStorage.setItem(LOCAL_STORAGE.isAuthorized, 'true');

        localStorage.setItem(LOCAL_STORAGE.incorrectPinCounter, '0');
        localStorage.setItem(LOCAL_STORAGE.refreshTimer, timer.toString());
        localStorage.removeItem(LOCAL_STORAGE.incorrectPinTimer);

        if (
          !localStorage.getItem(LOCAL_STORAGE.savedPushToken) ||
          localStorage.getItem(LOCAL_STORAGE.savedPushToken) === 'false'
        )
          getPushToken();

        onSuccess(response?.refreshToken);

        getPushToken();

        return response;
      },
      transformErrorResponse: (response: any) => {
        const counter = localStorage.getItem(LOCAL_STORAGE.incorrectPinCounter) || '0';
        const newCount = (Number(counter) + 1).toString();

        if (newCount === '1') {
          const day = 86400000;
          const timer = Date.now() + day;

          localStorage.setItem(LOCAL_STORAGE.incorrectPinTimer, timer.toString());
        }

        if (Number(counter) + 1 === 3) {
          localStorage.removeItem(LOCAL_STORAGE.salt);
          localStorage.removeItem(LOCAL_STORAGE.pinRefreshToken);
          localStorage.removeItem(LOCAL_STORAGE.incorrectPinCounter);
          localStorage.removeItem(LOCAL_STORAGE.refreshTimer);
          localStorage.removeItem(LOCAL_STORAGE.incorrectPinTimer);
        } else {
          localStorage.setItem(LOCAL_STORAGE.incorrectPinCounter, newCount);
        }

        return response;
        // return transformErrorWithAlert({
        //   response,
        //   message: AlertMessage.ERROR_LOGIN_FORBIDDEN,
        // });
      },
    }),
    refreshBIO: builder.mutation<
      any,
      { token: string; onSuccess?: (v?: any) => void; onError?: (v?: any) => void }
    >({
      query: ({ token }) => ({
        url: ApiEndpoints.REFRESH_TOKEN,
        endpoint: EndpointQueryNames.DEV_MAIN,
        method: 'POST',
        body: new URLSearchParams({
          refreshToken: token,
        }),
      }),
      transformResponse: (response: any, _, args) => {
        const { onSuccess } = { ...args };

        setTokensToCookie(response);
        sessionStorage.setItem(LOCAL_STORAGE.isAuthorized, 'true');
        history.push(Paths.HOME);

        if (
          !localStorage.getItem(LOCAL_STORAGE.savedPushToken) ||
          localStorage.getItem(LOCAL_STORAGE.savedPushToken) === 'false'
        )
          getPushToken();

        onSuccess?.(response?.refreshToken);

        return response;
      },
      transformErrorResponse: (response: ApiErrorResponse) => {
        if (response.status === HttpStatus.BAD_REQUEST) {
          localStorage.removeItem(LOCAL_STORAGE.biometrics);
          localStorage.removeItem(LOCAL_STORAGE.bioRefreshTimer);
        }

        return transformErrorWithAlert({
          response,
          message: AlertMessage.ERROR_OTHER,
        });
      },
    }),
    checkPassword: builder.mutation<
      void,
      { password: string; type?: 'pin' | 'bio'; onSuccess?: () => void; onError?: () => void }
    >({
      query: ({ password }) => ({
        url: ApiEndpoints.CHECK_PASSWORD,
        endpoint: EndpointQueryNames.DEV_MAIN,
        method: 'POST',
        body: {
          password,
        },
      }),
      transformResponse: (response: any, _, args) => {
        const { type, onSuccess, onError } = { ...args };

        if (type === 'bio') {
          const onSuccessBio = () => {
            const expireMs = (response?.refreshExpiresIn || 1) * 1000;
            const timer = Date.now() + expireMs;

            localStorage.setItem(LOCAL_STORAGE.biometrics, 'true');
            localStorage.setItem(LOCAL_STORAGE.bioRefreshTimer, timer.toString());
            onSuccess?.();
          };

          setNativeToken({ token: response?.refreshToken || '', onError, onSuccess: onSuccessBio });
        }

        return response;
      },
      transformErrorResponse: (response: ApiErrorResponse) =>
        transformErrorWithAlert({
          response,
          message: AlertMessage.ERROR_OTHER,
        }),
    }),

    pushToken: builder.mutation<void, PushTokenType>({
      query: ({ token, userId, deviceId, platform }) => ({
        url: ApiEndpoints.PUSH_TOKEN,
        endpoint: EndpointQueryNames.DEV_MAIN,
        method: 'POST',
        body: {
          token,
          userId,
          deviceId,
          platform,
        },
      }),
      transformResponse: () => localStorage.setItem(LOCAL_STORAGE.savedPushToken, 'true'),
      transformErrorResponse: () => localStorage.setItem(LOCAL_STORAGE.savedPushToken, 'false'),
    }),

    logout: builder.mutation<any, LogOutType>({
      query: ({ idToken }) => ({
        url: ApiEndpoints.LOGOUT,
        endpoint: EndpointQueryNames.DEV_MAIN,
        method: 'POST',
        body: { idToken },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRefreshPINMutation,
  useCheckPasswordMutation,
  useRefreshBIOMutation,
  usePushTokenMutation,
  useLogoutMutation,
} = authExtendedApiSlice;
