import { ApiAuthResponse } from '../types/api';
import { COOKIE_STORAGE } from '../constants/local-storage';
import { LOCAL_MOCK } from '../constants/general';

export const cookieParser = Object.fromEntries(
  document.cookie.split('; ').map((v) => v.split(/=(.*)/g).map(decodeURIComponent)),
);

export const setTokensToCookie = (response: ApiAuthResponse) => {
  const { refreshToken, accessToken, expiresIn, refreshExpiresIn, idToken } = { ...response };

  if (refreshToken && accessToken) {
    document.cookie = `${
      COOKIE_STORAGE.refreshToken
    }=${refreshToken}; max-age=${refreshExpiresIn}; secure; samesite=strict${
      LOCAL_MOCK ? ';' : 'domain=monetech.mx;'
    }`;
    document.cookie = `${
      COOKIE_STORAGE.accessToken
    }=${accessToken}; max-age=${expiresIn}; secure; samesite=strict${
      LOCAL_MOCK ? ';' : 'domain=monetech.mx;'
    }`;
    document.cookie = `${COOKIE_STORAGE.idToken}=${idToken}; secure; samesite=strict${
      LOCAL_MOCK ? ';' : 'domain=monetech.mx;'
    }`;
  } else throw new Error();
};
export const removeCookies = (cookies: string[]) => {
  cookies.forEach((cookie) => {
    document.cookie = `${cookie}=null; max-age=0;`;
  });
};

export const getCookie = (name: string) => {
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`),
  );

  return matches ? decodeURIComponent(matches[1]) : undefined;
};
