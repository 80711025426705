import { t } from 'i18next';

import { Paths } from '../routes/paths-enum';

type Tab = {
  key: string;
  title: string;
};

export const Tabs: Tab[] = [
  {
    key: Paths.HOME,
    title: t('screenFooter.home'),
  },
  {
    key: Paths.HISTORY,
    title: t('screenFooter.history'),
  },
  {
    key: Paths.CHAT,
    title: t('screenFooter.chat'),
  },
  {
    key: Paths.PROFILE,
    title: t('screenFooter.profile'),
  },
];
