import { appUrl, env } from '../constants/app-url';
import {
  reactAppUrlDev,
  reactAppUrlPreProd,
  reactAppUrlProd,
  reactAppUrlTest,
} from '../redux/constants/url';

export const getEnvUrl = () => {
  const location = window.location.href;

  let currentEnv = '';
  let apiObject = reactAppUrlTest;

  if (location.includes(appUrl.DEV)) {
    currentEnv = env.DEV;
    apiObject = reactAppUrlDev;
  }
  if (location.includes(appUrl.TEST)) {
    currentEnv = env.TEST;
    apiObject = reactAppUrlTest;
  }
  if (location.includes(appUrl.LOCAL)) {
    currentEnv = env.LOCAL;
    apiObject = reactAppUrlDev;
  }
  if (location.includes(appUrl.PROD)) {
    currentEnv = env.PROD;
    apiObject = reactAppUrlProd;
  }
  if (location.includes(appUrl.PREPROD)) {
    currentEnv = env.PREPROD;
    apiObject = reactAppUrlPreProd;
  }
  if (location.includes(appUrl.LOCAL_MOBILE)) {
    currentEnv = env.LOCAL_MOBILE;
    apiObject = reactAppUrlDev;
  }

  return {
    currentEnv,
    apiObject,
  };
};
