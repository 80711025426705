import { t } from 'i18next';

import { NextStep } from '../../constants/next-step';
import { ResultType } from '../../constants/result';
import { Paths } from '../../routes/paths-enum';
import { RequestStatus } from '../../types/general';
import { setApplicationId, setNextStep, setRequestStatus, setResultInfo } from '../modules/app';
import { history, store } from '../configure-store';
import { LOCAL_STORAGE } from '../../constants/local-storage';
import AppsFlyer from '../../utils/appsflyer';
import { AppsflyerEventName } from '../../types/appsflyer';

type RequestProcessingParams = {
  nextStep: string;
  applicationId?: string;
};

export const requestProcessing = ({ nextStep, applicationId }: RequestProcessingParams) => {
  if (applicationId) {
    store.dispatch(setApplicationId(applicationId));
  }
  if (nextStep === NextStep.cancelled) {
    history.push(Paths.REQUEST_STATUS);
    store.dispatch(setRequestStatus(RequestStatus.CANCELED));

    return;
  }
  if (nextStep === NextStep.expired) {
    history.push(Paths.REQUEST_STATUS);
    store.dispatch(setRequestStatus(RequestStatus.EXPIRED));

    return;
  }
  if (nextStep === NextStep.reviewAppData) {
    history.push(Paths.LOAN_APPLICATION);

    return;
  }
  if (nextStep === NextStep.rejected) {
    history.push(Paths.REQUEST_STATUS);
    store.dispatch(setRequestStatus(RequestStatus.REJECTED));

    return;
  }
  if (nextStep === NextStep.moratorium) {
    history.push(Paths.REQUEST_STATUS);
    store.dispatch(setRequestStatus(RequestStatus.MORATORIUM));

    return;
  }
  if (nextStep === NextStep.success) {
    const isFirstLoan = localStorage.getItem(LOCAL_STORAGE.getFirstLoan);

    AppsFlyer.sendEvent(isFirstLoan ? AppsflyerEventName.loanNext : AppsflyerEventName.loanFirst, {
      app_id: applicationId,
    });
    if (!isFirstLoan) {
      localStorage.setItem(LOCAL_STORAGE.getFirstLoan, 'true');
    }
    store.dispatch(
      setResultInfo({
        status: ResultType.success,
        title: t('result.successfully'),
        description: t('result.withinAFewMinutes'),
        buttons: [{ type: 'redirect', path: Paths.HOME, text: t('result.ok') }],
      }),
    );
    history.push(Paths.RESULT_PAGE);

    return;
  }
  if (nextStep === NextStep.registration) {
    history.push(Paths.REGISTRATION);

    return;
  }
  if (nextStep === NextStep.nubarium || nextStep === NextStep.jumio) {
    store.dispatch(setNextStep(nextStep));
    history.push(Paths.INTRO_SCREEN, { nextStep });

    return;
  }
  if (nextStep === NextStep.processingApp) {
    history.push(Paths.PROCESSING_APP);

    return;
  }
  if (nextStep === NextStep.payoutDestination) {
    history.push(Paths.DESTINATION_ACCOUNT);

    return;
  }
  if (nextStep === NextStep.approveProposal) {
    history.push(Paths.APPLICATION_APPROVED);
  }
};
