import { t } from 'i18next';

export const AlertMessage = {
  ERROR_LOGIN_FORBIDDEN: t('alertMessage.errorLoginForbidden'),
  ERROR_OTHER: t('alertMessage.errorOther'),
  WARNING_CONTACT_ADMIN: t('alertMessage.warningContactAdmin'),
  INFO_SMS_SEND: t('alertMessage.infoSmsSend'),
  ERROR_LOGIN: t('alertMessage.errorLogin'),
  ERROR_SMS_SEND: t('alertMessage.errorSmsSend'),
  ERROR_SMS_SEND_BLOCK: t('alertMessage.errorSmsSendBlock'),
  ERROR_WRONG_OTP: t('alertMessage.errorWrongOtp'),
  INVALID_PASSWORD: t('alertMessage.invalidPassword'),
  INVALID_PHONE_NUMBER: t('alertMessage.invalidPhoneNumber'),
  ERROR_CODE_EXPIRED: t('alertMessage.errorCodeExpired'),
  ERROR_CODE_UNABLE: t('alertMessage.errorCodeUnable'),
  ERROR_CODE_EXCEEDED: t('alertMessage.errorCodeExceeded'),
  ALREADY_REGISTERED: t('alertMessage.alreadyRegistered'),
  PASSWORD_BLACKLIST: t('alertMessage.passwordBlacklist'),
  PASSWORD_CONTAINS_USERNAME: t('alertMessage.passwordContainsUsername'),
  PASSWORD_INCORRECT: t('alertMessage.passwordIncorrect'),
  PASSWORD_MUST_MATCH: t('alertMessage.newPasswordMustNotMatch'),
  BAD_REQUEST: t('alertMessage.badRequest'),
  CHECK_VALUE: t('alertMessage.checkValue'),
  TIMEOUT_EXCEEDED: t('alertMessage.timeoutExceeded'),
  CHECK_STATUS_SUPPORT: t('alertMessage.checkStatusSupport'),
  INCORRECT_DATA_SUPPORT: t('alertMessage.incorrectDataSupport'),
  EMAIL_NOT_SET: t('alertMessage.emailNotSet'),
  TEMPORARY_PROBLEMS: t('alertMessage.temporaryProblems'),
  TEMPORARY_ERROR_OCCURRED: t('alertMessage.temporaryErrorOccurred'),
  HTTP_400: t('alertMessage.http400'),
  SMS_CODE_SENT: t('alertMessage.smsCodeSent'),
  FAILED_VERIFY_SMS: t('alertMessage.failedVerifySms'),
  INCORRECT_ACCOUNT_NUMBER: t('alertMessage.incorrectAccountNumber'),
  MAKE_SURE_ACCOUNT: t('alertMessage.makeSureAccount'),
  CHECK_ACCURACY_ENTERED_CURP: t('alertMessage.checkAccuracyEnteredCurp'),
  YOU_ALREADY_REQUEST_PROFILE_DELETION: t('alertMessage.youAlreadyRequestedProfileDeletion'),
  USER_WITH_CURP: t('alertMessage.userWithCurpNumber'),
  ALREADY_EXISTS_CONNECTED: t('alertMessage.alreadyExistsConnectedToPhone'),
  PLEASE_LOGIN_INTO_ACCOUNT: t('alertMessage.pleaseLoginIntoYourAccount'),
};

export const ALERT_DELETE_TIMEOUT = 3000;
