// TODO: delete
export const reactAppUrlMock = {
  REACT_APP_BASE_MOCK_LOCAL_URL: 'http://localhost:8080/api/',
  REACT_APP_BASE_MOCK_URL: 'https://mock.dev.monetech.mx/api/',
};

export const reactAppUrlDev = {
  REACT_APP_BASE_URL: 'https://api.dev.monetech.mx/',
  REACT_APP_KEYCLOAK_URL: 'https://api.dev.monetech.mx/',
  REACT_APP_MOCK_KEYCLOAK_URL: 'https://mock.dev.monetech.mx/api/',
  REACT_APP_BASE_LOAN_URL: 'https://api.dev.monetech.mx/loan-origination/',
  REACT_APP_BASE_USER_URL: 'https://api.dev.monetech.mx/user-management/',
  REACT_APP_BASE_NUBARIUM_URL: 'https://api.dev.monetech.mx/nubarium-handler/',
  REACT_APP_BASE_LOAN_DETAIL_URL: 'https://api.dev.monetech.mx/loan-management/',
};

export const reactAppUrlTest = {
  REACT_APP_BASE_URL: 'https://api.test.dev.monetech.mx/',
  REACT_APP_KEYCLOAK_URL: 'https://api.test.dev.monetech.mx/',
  REACT_APP_MOCK_KEYCLOAK_URL: 'https://mock.dev.monetech.mx/api/',
  REACT_APP_BASE_LOAN_URL: 'https://api.test.dev.monetech.mx/loan-origination/',
  REACT_APP_BASE_USER_URL: 'https://api.test.dev.monetech.mx/user-management/',
  REACT_APP_BASE_NUBARIUM_URL: 'https://api.test.dev.monetech.mx/nubarium-handler/',
  REACT_APP_BASE_LOAN_DETAIL_URL: 'https://api.test.dev.monetech.mx/loan-management/',
};

export const reactAppUrlProd = {
  REACT_APP_BASE_URL: 'https://api.monetech.mx/',
  REACT_APP_KEYCLOAK_URL: 'https://api.monetech.mx/',
  REACT_APP_MOCK_KEYCLOAK_URL: 'https://mock.dev.monetech.mx/api/',
  REACT_APP_BASE_LOAN_URL: 'https://api.monetech.mx/loan-origination/',
  REACT_APP_BASE_USER_URL: 'https://api.monetech.mx/user-management/',
  REACT_APP_BASE_NUBARIUM_URL: 'https://api.monetech.mx/nubarium-handler/',
  REACT_APP_BASE_LOAN_DETAIL_URL: 'https://api.monetech.mx/loan-management/',
};

export const reactAppUrlPreProd = {
  REACT_APP_BASE_URL: 'https://api.preprod.monetech.mx/',
  REACT_APP_KEYCLOAK_URL: 'https://api.preprod.monetech.mx/',
  REACT_APP_MOCK_KEYCLOAK_URL: 'https://mock.dev.monetech.mx/api/',
  REACT_APP_BASE_LOAN_URL: 'https://api.preprod.monetech.mx/loan-origination/',
  REACT_APP_BASE_USER_URL: 'https://api.preprod.monetech.mx/user-management/',
  REACT_APP_BASE_NUBARIUM_URL: 'https://api.preprod.monetech.mx/nubarium-handler/',
  REACT_APP_BASE_LOAN_DETAIL_URL: 'https://api.preprod.monetech.mx/loan-management/',
};
