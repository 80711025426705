import { Outlet, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { RequireAuth } from '../require-auth';
import { ScreenFooter } from '../screen-footer';
import styles from './main-layout.module.css';
import { Paths } from '../../routes/paths-enum';

export const MainLayout = () => {
  const { pathname } = useLocation();

  const isChatPage = pathname.includes(Paths.CHAT);
  const isGrayBackground = [Paths.HISTORY as string, Paths.PROFILE as string].includes(pathname);

  return (
    <RequireAuth>
      <div
        className={classNames(
          styles.wrapper,
          isChatPage && styles.chatWrapper,
          isGrayBackground && styles.profileWrapper,
        )}
      >
        <Outlet />
        <ScreenFooter />
      </div>
    </RequireAuth>
  );
};
