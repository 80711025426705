import { AlertMessage } from '../../constants/alert-message';
import { ApiErrorResponse } from '../../types/api';
import { ApiEndpoints } from '../constants/api';
import { ApiGroupNames, EndpointQueryNames } from '../constants/api-group-names';
import { EndpointNames } from '../constants/endpoint-names';
import { transformErrorWithAlert } from '../helpers/transform-error-with-alert';
import { ChatPayloadType, ChatType } from '../../types/chat';
import { LOCAL_STORAGE } from '../../constants/local-storage';
import { store } from '../configure-store';
import { authExtendedApiSlice } from './auth';
import { isWebContext } from '../../utils/is-web-context';

import { apiSlice } from '.';

export const chatApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChatData: builder.query<ChatType, ChatPayloadType>({
      query: () => ({
        url: ApiEndpoints.CHAT_DATA,
        endpoint: EndpointQueryNames.DEV_USER_MANAGEMENT,
        method: 'GET',
        apiGroupName: ApiGroupNames.CHAT,
        name: EndpointNames.CHAT,
      }),
      transformResponse: (response: ChatType, _, args) => {
        const { token } = args;

        if (
          sessionStorage.getItem(LOCAL_STORAGE.isAuthorized) &&
          (!localStorage.getItem(LOCAL_STORAGE.savedPushToken) ||
            localStorage.getItem(LOCAL_STORAGE.savedPushToken) === 'false') &&
          !isWebContext
        ) {
          const payload = {
            token,
            userId: (response.userId && response.userId) || '',
            deviceId: localStorage.getItem(LOCAL_STORAGE.deviceId) || '',
            platform: localStorage.getItem(LOCAL_STORAGE.platform) || '',
          };

          store.dispatch(authExtendedApiSlice.endpoints?.pushToken.initiate(payload));
        }

        return {
          ...response,
        };
      },
      transformErrorResponse: (response: ApiErrorResponse) => {
        transformErrorWithAlert({
          response,
          message: AlertMessage.ERROR_OTHER,
        });
        localStorage.setItem(LOCAL_STORAGE.savedPushToken, 'false');
      },
    }),
  }),
});

export const { useGetChatDataQuery, useLazyGetChatDataQuery } = chatApiSlice;
