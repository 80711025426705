export enum AppsflyerEventName {
  singUp = 'singUp',
  tapLinkTermsOfService = 'tapLinkTermsOfService',
  tapLinkPrivacyPolicy = 'tapLinkPrivacyPolicy',
  registerPhone = 'registerPhone',
  registerPassword = 'registerPassword',
  identificationNubarium = 'identificationNubarium',
  identificationJumio = 'identificationJumio',
  userData = 'userData',
  inputAccount = 'inputAccount',
  userDataConfirm = 'userDataConfirm',
  onReview = 'onReview',
  applicationScreenApproved = 'applicationScreenApproved',
  applicationUserApproved = 'applicationUserApproved',
  applicationSMSConfirm = 'applicationSMSConfirm',
  loanFirst = 'loanFirst',
  loanNext = 'loanNext',
  home = 'Home',
}
