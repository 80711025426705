import { NavBar } from 'antd-mobile';
import { CloseOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import saveAs from 'file-saver';

import { ScreenHeaderProps } from './types';
import styles from './screen-header.module.css';
import { ReactComponent as DownlandOutline } from '../../assets/downlandOutline.svg';
import { isWebContext } from '../../utils/is-web-context';

export const ScreenHeader = ({
  action,
  title,
  backArrow,
  additionalClass,
  downloadPdfLink,
}: ScreenHeaderProps) => {
  const getBackArrow = () => {
    switch (backArrow) {
      case 'none':
        return false;
      case 'close':
        return <CloseOutline />;
      case 'arrow':
        return true;
      default:
        return true;
    }
  };

  const handleDownload = () => {
    if (isWebContext) {
      saveAs(downloadPdfLink || '', `${title}.pdf`);
    } else {
      const link = document.createElement('a');

      link.href = downloadPdfLink || '';
      link.setAttribute('download', `${title}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className={classNames(styles.header, additionalClass)}>
      <NavBar
        onBack={action}
        className={classNames(styles.navbar, downloadPdfLink && styles.navbarLinkHas)}
        backIcon={getBackArrow()}
      >
        <div className={classNames(styles.title, downloadPdfLink && styles.titleSmall)}>
          {title}
        </div>
        {downloadPdfLink && (
          <div className={styles.downloadLink} onClick={handleDownload}>
            <DownlandOutline />
          </div>
        )}
      </NavBar>
    </div>
  );
};
