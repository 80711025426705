import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { Statuses } from '../../types/general';
import { addAlertTypeToErrorResponse } from './add-alert-type-to-error-response';

type TransformErrorWithAlertParams = {
  response: FetchBaseQueryError;
  message: string;
  type?: Statuses;
  skipNotFoundAndForbidden?: boolean;
};

export const transformErrorWithAlert = ({
  response,
  message,
  type = Statuses.ERROR,
  skipNotFoundAndForbidden = false,
}: TransformErrorWithAlertParams) => ({
  ...addAlertTypeToErrorResponse(response, {
    type,
    message,
  }),
  skipNotFoundAndForbidden,
});
