import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { PageWrapperType } from './types';
import styles from './page-wrapper.module.css';
import { PATH_SCREEN_FOOTER } from '../../constants/paths-screen-footer';
import { LOCAL_STORAGE } from '../../constants/local-storage';

export const PageWrapper = ({ children, className, fillFooter }: PageWrapperType) => {
  const { pathname } = useLocation();
  const refreshToken = sessionStorage.getItem(LOCAL_STORAGE.isAuthorized);
  const [footerHeight, setFooterHeight] = useState<number>(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const isTabBar = PATH_SCREEN_FOOTER.includes(pathname) && refreshToken;

  const setContainerSize = useCallback(() => {
    if (containerRef.current) {
      const element = containerRef.current.querySelector('.adm-form-footer');

      if (element) {
        const containerWidth = containerRef.current.offsetWidth;
        const specialStyles = {
          position: 'fixed',
          top: 'auto',
          bottom: isTabBar ? '49px' : '0',
          left: '50%',
          transform: 'translateX(-50%)',
          width: `calc(${containerWidth}px - 32px)`,
          zIndex: '4',
          backgroundColor: !fillFooter && 'white',
          background: fillFooter && 'var(--linear-gradient)',
          padding: 'var(--gap-m)',
        };

        Object.assign((element as HTMLElement).style, specialStyles);

        setFooterHeight(isTabBar ? Number(element?.clientHeight) + 49 : element?.clientHeight);
      }
    }
  }, [fillFooter, isTabBar]);

  const handleScroll = useCallback(() => {
    setContainerSize();
  }, [setContainerSize]);

  useEffect(() => {
    setContainerSize();
  }, [setContainerSize]);

  useEffect(() => {
    window.addEventListener('resize', setContainerSize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', setContainerSize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, setContainerSize]);

  return (
    <div
      ref={containerRef}
      className={classNames(styles.wrapper, className)}
      style={{ paddingBottom: `${footerHeight + 20}px` }}
    >
      {children}
    </div>
  );
};
