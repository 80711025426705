export const setLocalStorageItem = (name: string, value: string) => {
  const oldValue = localStorage.getItem(name) || undefined;

  localStorage.setItem(name, value);
  const event = new StorageEvent('storage', {
    key: name,
    oldValue,
    newValue: value,
  });

  window.dispatchEvent(event);
};

export const removeLocalStorageItem = (name: string) => {
  const oldValue = localStorage.getItem(name) || undefined;

  localStorage.removeItem(name);
  const newValue = undefined;
  const event = new StorageEvent('storage', {
    key: name,
    oldValue,
    newValue,
  });

  window.dispatchEvent(event);
};

export const removeSessionStorageItem = (name: string) => {
  const oldValue = localStorage.getItem(name) || undefined;

  sessionStorage.removeItem(name);
  const newValue = undefined;
  const event = new StorageEvent('storage', {
    key: name,
    oldValue,
    newValue,
  });

  window.dispatchEvent(event);
};
