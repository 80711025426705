import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import styles from './error-page.module.css';
import { PageWrapper } from '../../components/page-wrapper';
import { ScreenHeader } from '../../components/screen-header';
import { history } from '../../redux/configure-store';

type ErrorPageProps = {
  error?: string;
  setState?: (val?: any) => void;
};

export const ErrorPage = ({
  error = t('errorPage.somethingWentWrong'),
  setState,
}: ErrorPageProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      const timeoutId = setTimeout(() => setState?.(), 5);

      return () => clearTimeout(timeoutId);
    });

    return () => {
      unlisten();
    };
  }, [setState]);

  return (
    <PageWrapper>
      <ScreenHeader title={t('errorPage.title')} action={handleClick} />
      <div className={styles.wrapper}>
        {t('errorPage.anErrorOccurred')} - {error}
      </div>
    </PageWrapper>
  );
};
