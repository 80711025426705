import { Navigate } from 'react-router-dom';

import { Paths } from '../../routes/paths-enum';
import { LOCAL_STORAGE } from '../../constants/local-storage';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const refreshToken = LOCAL_STORAGE.isAuthorized;

  return refreshToken ? children : <Navigate to={Paths.MAIN} replace={true} />;
};
