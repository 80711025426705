import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TabBar } from 'antd-mobile';
import classNames from 'classnames';

import { ReactComponent as HomeSvg } from '../../assets/tab-icons/home.svg';
import { ReactComponent as HistorySvg } from '../../assets/tab-icons/app.svg';
import { ReactComponent as ChatSvg } from '../../assets/tab-icons/chat.svg';
import { ReactComponent as ProfileSvg } from '../../assets/tab-icons/ProfileOutline.svg';
import { PATH_SCREEN_FOOTER } from '../../constants/paths-screen-footer';
import { Tabs } from '../../constants/tabs';
import styles from './screen-footer.module.css';
import { Paths } from '../../routes/paths-enum';
import { LOCAL_STORAGE } from '../../constants/local-storage';

export const ScreenFooter = () => {
  const { pathname } = useLocation();
  const refreshToken = sessionStorage.getItem(LOCAL_STORAGE.isAuthorized);
  const [isAuth, setIsAuth] = useState(sessionStorage.getItem(LOCAL_STORAGE.isAuthorized));
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState(pathname);

  useEffect(() => {
    if (sessionStorage.getItem(LOCAL_STORAGE.isAuthorized))
      setIsAuth(sessionStorage.getItem(LOCAL_STORAGE.isAuthorized));
  }, [refreshToken]);

  useEffect(() => {
    setActiveKey(pathname);
  }, [pathname]);

  const setRouteActive = (value: string) => {
    navigate(value);
    setActiveKey(value);
  };

  const getTabIcon = (key: string, className: string) => {
    switch (key) {
      case Paths.HOME:
        return <HomeSvg stroke={className} />;
      case Paths.HISTORY:
        return <HistorySvg stroke={className} />;
      case Paths.PROFILE:
        return <ProfileSvg stroke={className} />;
      case Paths.CHAT:
        return <ChatSvg stroke={className} />;
    }
  };

  const isActive = (key: string) => {
    const activePaths: { [key: string]: string[] } = {
      [Paths.HOME]: [Paths.HOME, Paths.MAIN],
      [Paths.HISTORY]: [Paths.HISTORY],
      [Paths.PROFILE]: [
        Paths.PROFILE,
        Paths.CONTACT_DATA,
        Paths.PERSONAL_DATA,
        Paths.SETTINGS,
        Paths.CHANGE_PASSWORD,
      ],
      [Paths.CHAT]: [Paths.CHAT],
    };

    return activePaths[key]?.includes(pathname);
  };

  if (!PATH_SCREEN_FOOTER.includes(pathname) || !isAuth) return null;

  return (
    <div className={styles.footer}>
      <TabBar className={styles.tabBar} activeKey={activeKey} onChange={setRouteActive}>
        {Tabs.map((item) => (
          <TabBar.Item
            key={item.key}
            title={item.title}
            icon={getTabIcon(item.key, isActive(item.key) ? 'var(--green-color)' : '')}
            className={classNames(styles.tabBarItem, isActive(item.key) && styles.activeItem)}
          />
        ))}
      </TabBar>
    </div>
  );
};
