export const API_BASE_LOAN_URL = process.env.REACT_APP_BASE_LOAN_DEV_URL as string;
export const API_BASE_USER_URL = process.env.REACT_APP_BASE_USER_DEV_URL as string;
export const API_BASE = process.env.REACT_APP_BASE_DEV_URL as string;
export const API_BASE_NUBARIUM_URL = process.env.REACT_APP_BASE_NUBARIUM_URL as string;

export const AUTH_URL = process.env.REACT_APP_KEYCLOAK_URL as string;

export const API_MOCK_URL = process.env.REACT_APP_BASE_MOCK_URL as string;
export const API_LOCAL_MOCK_URL = process.env.REACT_APP_BASE_MOCK_LOCAL_URL as string;

export const LOCAL_MOCK = process.env.REACT_APP_MOCK_LOCAL as string;
