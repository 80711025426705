import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import { enTranslation } from './locales/en/translation';
import { esTranslation } from './locales/es/translation';

const changeLanguage = (lang: string) => {
  i18n.changeLanguage(lang);
};

i18n.use(initReactI18next).init({
  fallbackLng: 'es',
  interpolation: { escapeValue: false },
  resources: {
    en: {
      translation: enTranslation,
    },
    es: {
      translation: esTranslation,
    },
  },
});

export { i18n, changeLanguage };
