import { useCallback } from 'react';

import { useAppDispatch } from './use-redux-hook';
import { COOKIE_STORAGE, LOCAL_STORAGE } from '../constants/local-storage';
import { clearStateOnLogout } from '../redux/modules/app';
import { apiSlice } from '../redux/services';
import { removeCookies } from '../utils/cookie-parser';
import { removeLocalStorageItem, removeSessionStorageItem } from '../utils/storage-handlers';

export const useLogout = () => {
  const dispatch = useAppDispatch();

  const logOut = useCallback(() => {
    removeSessionStorageItem(LOCAL_STORAGE.xSessionId);
    removeCookies([
      COOKIE_STORAGE.accessToken,
      COOKIE_STORAGE.refreshToken,
      COOKIE_STORAGE.idToken,
    ]);
    removeSessionStorageItem(LOCAL_STORAGE.isAuthorized);

    removeSessionStorageItem(LOCAL_STORAGE.salt);
    removeLocalStorageItem(LOCAL_STORAGE.pinRefreshToken);
    removeLocalStorageItem(LOCAL_STORAGE.salt);
    removeLocalStorageItem(LOCAL_STORAGE.incorrectPinCounter);
    removeLocalStorageItem(LOCAL_STORAGE.incorrectPinTimer);

    setTimeout(() => {
      dispatch(clearStateOnLogout());
      dispatch(apiSlice.util.resetApiState());
      removeSessionStorageItem(LOCAL_STORAGE.userLastActivityTime);
    }, 0);
  }, [dispatch]);

  return {
    logOut,
  };
};
