import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { AlertErrorApi, AppStatuses } from '../../types/app-alert-type';

export const addAlertTypeToErrorResponse = (
  response: FetchBaseQueryError,
  alert: AppStatuses,
): AlertErrorApi => ({
  ...response,
  alert,
});
