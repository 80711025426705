import { Paths } from '../routes/paths-enum';

export const PATH_SCREEN_FOOTER = [
  Paths.APP,
  Paths.CALCULATOR,
  Paths.CHAT,
  Paths.HISTORY,
  Paths.MAIN,
  Paths.HOME,
  Paths.PROFILE,
  Paths.PERSONAL_DATA,
  Paths.CONTACT_DATA,
  Paths.SETTINGS,
  Paths.CHANGE_PASSWORD,
  Paths.LOAN_DETAILS,
] as string[];
