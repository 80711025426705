import { useEffect } from 'react';

export const useScrollToTop = (dependency: unknown) => {
  useEffect(() => {
    const timeoutID = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);

    return () => clearTimeout(timeoutID);
  }, [dependency]);
};
