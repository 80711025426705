import { Provider } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { ReactNode } from 'react';

import { store, history } from './redux/configure-store';
import { CustomNoticeBar } from './components/custom-notice-bar';
import { useScrollToTop } from './hooks/use-scroll-to-top';
import { routes } from './routes/routes';
import { getNativeDeviceInfo } from './hooks/use-get-native-info';
import './app.css';
import { usePinTimers } from './hooks/use-pin-timers';

const ScrollToTopWrapper = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const { pathname } = useLocation();

  useScrollToTop(pathname);
  usePinTimers();

  return children;
};

getNativeDeviceInfo();

export const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <ScrollToTopWrapper>
        {routes}
        <CustomNoticeBar />
      </ScrollToTopWrapper>
    </Router>
  </Provider>
);
