import { isFulfilled, isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

import { appSelector, deleteFirstPopupAlert, setPopupAlert } from '../modules/app';

export const popupAlertsMiddleware: Middleware =
  ({ dispatch, getState }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      const state = getState();
      const { alert } = action.payload;
      const popupAlertMessage = action.payload.alert?.message;
      const { alerts } = appSelector(state);
      const isPopupAlertAlreadyExists = alerts.some(({ message }) => message === popupAlertMessage);
      const isMustShowAlert = popupAlertMessage && !isPopupAlertAlreadyExists;

      if (alert && isMustShowAlert) {
        dispatch(setPopupAlert(alert));
      }
    }
    if (isFulfilled(action)) {
      dispatch(deleteFirstPopupAlert());
    }

    return next(action);
  };
