export const LOCAL_STORAGE = {
  xSessionId: 'x-session-id',
  incorrectPinCounter: 'incorrectPinCounter',
  pinRefreshToken: 'pinRefreshToken',
  salt: 'salt',
  xFingerprint: 'x-fingerprint',
  userLastActivityTime: 'userLastActivityTime',
  refreshTimer: 'refreshTimer',
  incorrectPinTimer: 'incorrectPinTimer',
  isAuthorized: 'isAuthorized',
  eqId: 'eqId',
  refCode: 'refCode',
  biometrics: 'biometrics',
  bioRefreshTimer: 'bioRefreshTimer',
  getFirstLoan: 'getFirstLoan',
  deviceId: 'deviceId',
  platform: 'platform ',
  savedPushToken: 'savedPushToken',
};

export const COOKIE_STORAGE = {
  refreshToken: 'refreshToken',
  accessToken: 'accessToken',
  idToken: 'idToken',
};
