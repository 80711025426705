/* eslint-disable no-console */
import { LOCAL_STORAGE } from '../constants/local-storage';
import { store } from '../redux/configure-store';
import { setDeviceNativeInfo, setPushToken, initialState } from '../redux/modules/app';
import { GGL_REF_CODE, RANDOM_DEEP_LINK } from '../utils/random-deep-link';
import { env } from '../constants/app-url';
import { authExtendedApiSlice } from '../redux/services/auth';
import { getEnvUrl } from '../utils/get-env-url';
import { chatApiSlice } from '../redux/services/chat';

export type DeviceInfo = {
  deviceId: string;
  appVersion: string;
  system: string;
  deviceModel: string;
  osVersion: string;
  platform: string;
  deepLink: {
    status: string;
    value: string | null;
    subs: string | null;
  };
};

export enum NativeOperations {
  GET_PUSH_TOKEN = 'GET_PUSH_TOKEN',
  CHECK_TOKEN_PROTECTION = 'CHECK_TOKEN_PROTECTION',
  PROTECT_TOKEN = 'PROTECT_TOKEN',
  RETRIEVE_TOKEN = 'RETRIEVE_TOKEN',
}

type CallNativeType = {
  operation: NativeOperations;
  parameters?: any;
  callBackFoo: (p?: any) => void;
};

type SetTokenTypePayload = OnResponseType & {
  token: string;
};

type OnResponseType = {
  onSuccess?: (token?: string) => void;
  onError?: () => void;
};

enum ResStatusEnum {
  OK = 'OK',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
}

type NativeCallResponse = {
  status: ResStatusEnum;
  token?: string;
};

type NativeGetPushTokenResponse = {
  token: string;
};

export const getNativeDeviceInfo = () => {
  const { versionGplayReview } = initialState;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleNativeInfo(info: DeviceInfo) {
    const updateDeviceInfo = (deviceInfo: DeviceInfo) => {
      const updatedValue =
        deviceInfo?.deepLink?.value && deviceInfo.deepLink.value !== 'null'
          ? deviceInfo?.deepLink?.value
          : RANDOM_DEEP_LINK;

      if (info.appVersion === versionGplayReview) {
        localStorage.setItem(LOCAL_STORAGE.refCode, GGL_REF_CODE);
      } else if (!localStorage.getItem(LOCAL_STORAGE.refCode)) {
        localStorage.setItem(LOCAL_STORAGE.refCode, updatedValue);
      }

      return {
        ...deviceInfo,
        deepLink: {
          ...deviceInfo?.deepLink,
          value: updatedValue,
        },
      };
    };

    if (info) {
      store.dispatch(setDeviceNativeInfo(updateDeviceInfo(info)));
    }

    if (!localStorage.getItem(LOCAL_STORAGE.deviceId)) {
      localStorage.setItem(LOCAL_STORAGE.deviceId, info.deviceId);
    }

    if (!localStorage.getItem(LOCAL_STORAGE.platform)) {
      localStorage.setItem(LOCAL_STORAGE.platform, info.platform);
    }
  }
  const getNativeInfo = () => {
    const callbackName = 'handleNativeInfo';

    if (window.Android && window.Android.monetechGetNativeInfo) {
      window.Android.monetechGetNativeInfo(JSON.stringify({ callbackName }));
    }

    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.monetechGetNativeInfo
    ) {
      window.webkit.messageHandlers.monetechGetNativeInfo.postMessage(
        JSON.stringify({ callbackName }),
      );
    }
  };

  (window as any).handleNativeInfo = handleNativeInfo;

  getNativeInfo();
};

const callNative = ({ operation, parameters = {}, callBackFoo }: CallNativeType) => {
  const { currentEnv } = getEnvUrl();

  if ([env.PREPROD, env.PROD].includes(currentEnv)) {
    return;
  }
  const callbackName = 'handleCallNative';

  if (window.Android && window.Android.monetechCallNative) {
    window.Android.monetechCallNative(JSON.stringify({ operation, parameters, callbackName }));
  }
  (window as any).handleCallNative = callBackFoo;
};

export const bioAuth = ({ onSuccess, onError }: OnResponseType) => {
  const handleBio = (res: NativeCallResponse) => {
    // const { currentEnv } = getEnvUrl();

    // if (currentEnv === env.DEV) {
    //   // eslint-disable-next-line no-alert
    //   alert(
    //     JSON.stringify({
    //       action: 'auth',
    //       response: res,
    //     }),
    //   );
    // }
    if (res.status === ResStatusEnum.OK && res?.token) {
      return store.dispatch(
        authExtendedApiSlice.endpoints?.refreshBIO.initiate({ token: res.token, onSuccess }),
      );
    }
    if (res.status === ResStatusEnum.CANCELED) {
      return onError?.();
    }
    localStorage.removeItem(LOCAL_STORAGE.bioRefreshTimer);
    localStorage.removeItem(LOCAL_STORAGE.biometrics);
    onError?.();
  };

  callNative({ operation: NativeOperations.RETRIEVE_TOKEN, callBackFoo: handleBio });
};

export const checkBioAbility = ({ onError, onSuccess }: OnResponseType) => {
  const handleCheckAbility = (res: NativeCallResponse) => {
    // const { currentEnv } = getEnvUrl();
    //
    // if (currentEnv === env.DEV) {
    //   // eslint-disable-next-line no-alert
    //   alert(
    //     JSON.stringify({
    //       action: 'check abil',
    //       response: res,
    //     }),
    //   );
    // }
    if (res?.status === ResStatusEnum.OK) {
      onSuccess?.();
    } else {
      onError?.();
    }
  };

  callNative({
    operation: NativeOperations.CHECK_TOKEN_PROTECTION,
    callBackFoo: handleCheckAbility,
  });
};

export const setNativeToken = ({ token, onSuccess, onError }: SetTokenTypePayload) => {
  const setToken = (res: NativeCallResponse) => {
    // const { currentEnv } = getEnvUrl();

    // if (currentEnv === env.DEV) {
    //   // eslint-disable-next-line no-alert
    //   alert(
    //     JSON.stringify({
    //       payloadToken: token,
    //       action: 'set token',
    //       response: res,
    //     }),
    //   );
    // }
    if (res?.status === 'OK') {
      onSuccess?.();
    } else {
      onError?.();
    }
  };

  callNative({
    operation: NativeOperations.PROTECT_TOKEN,
    parameters: { token },
    callBackFoo: setToken,
  });
};

export const getPushToken = () => {
  const { currentEnv } = getEnvUrl();

  if ([env.PREPROD, env.PROD].includes(currentEnv)) {
    return;
  }
  const getToken = (res: NativeGetPushTokenResponse) => {
    console.log(res);
    // eslint-disable-next-line no-alert
    alert(
      JSON.stringify({
        action: 'get token',
        response: res,
      }),
    );
    if (res?.token) {
      store.dispatch(setPushToken(res.token));

      return store.dispatch(chatApiSlice.endpoints?.getChatData.initiate({ token: res?.token }));
    }
  };

  callNative({
    operation: NativeOperations.GET_PUSH_TOKEN,
    callBackFoo: getToken,
  });
};

// window.Android.monetechCallNative(
//   JSON.stringify({
//     operation,
//     parameters,
//     callbackName,
//   }),
// );
