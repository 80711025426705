export const USER_ACTIVITY_EVENTS = [
  'load',
  // 'mousemove',
  'click',
  'contextmenu',
  'scroll',
  'drag',
  'copy',
  'cut',
  'paste',
  'touchstart',
  'touchend',
  'touchcancel',
  'touchmove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'MSPointerDown',
  'MSPointerMove',
];
