export const enTranslation = {
  notFoundPage: {
    title: 'Page not found',
    buttonTitle: 'Not found page',
  },
  errorPage: {
    title: 'Error page',
    anErrorOccurred: 'An error occurred',
    somethingWentWrong: 'Something went wrong...',
  },
  calculator: {
    title: 'Loan calculator',
    titleButtonTake: 'Take a loan',
    titleButtonApply: 'Get',
    borrowedAmount: 'Amount to borrow',
    repaymentPeriod: 'Repayment period',
    days: 'days',
    calculation: 'Calculation',
    maturityDate: 'Repayment date',
    repaymentAmount: 'Repayment amount',
    calculationDescription:
      'The calculated refund amounts are preliminary and will be finalized after the completion of the loan application',
    letYourDreams: 'Let your dreams come true, it’s easy',
    freeLoan:
      'Congratulations, your loan now is free of interest and charges, you return only what you take!',
    interestRate: 'Interest rate',
    commissionDescription1: '* Includes IVA',
    commissionDescription2: 'MXN',
  },
  authorization: {
    title: 'Log in',
    button: 'Log in',
    phoneInput: 'Phone number',
    passwordInput: 'Password',
    phoneError: 'Enter phone number',
    passwordInputPlaceholder: 'Enter password',
    forgotYourPassword: 'Forgot your password?',
  },
  registration: {
    title: 'Sign up',
    titlePassRecovery: 'Password Recovery',
    smsCodeConfirmation: 'SMS Code Confirmation',
    inputLabel: 'Phone number',
    agreements: {
      personalData: 'I consent to process my personal data',
      loanBureau: 'I consent to the request to access my credit history',
      ownBehalf: 'I am acting on my own behalf',
    },
    smsScreenTitle: 'SMS',
    smsScreenApplicationTitle: 'SMS code',
    smsScreenChangePasswordTitle: 'Confirmation',
    smsInputLabel: 'SMS code',
    smsLengthError: 'Enter your SMS code',
    phoneInputDescription: 'Confirmation code has been sent to this phone number',
    changePasswordInputDescription: 'Confirm the password change with the code from your SMS',
    smsInputDescription: 'Enter the confirmation code',
    phoneError: 'Enter phone number',
    agreementsError: 'Required to fill out',
    resendTheCodeButton: 'Resend the code',
    resendTheCodeAfter: 'Resend the code in',
    smsCodeSentTo: 'SMS code sent to',
    clickingContinue: 'By clicking Continue you agree to our',
    termsService: ' Terms of Service',
    privacyPolicy: 'Privacy Policy',
    and: 'and the electronic media clause such as PIN',
    byContinuingAccepting: '. By continuing you are accepting the',
    consultation: 'consultation',
    ofYourCreditHistory: 'of your credit history.',
    buttonTitle: 'Continue',
    buttonApplicationTitle: 'Confirm',
  },
  contactInfo: {
    title: 'Contact information',
    firstName: 'First and second name',
    fatherLastName: "Father's last name",
    motherLastName: "Mother's last name",
    occupation: 'Occupation',
    occupationPlaceholder: 'Select',
    curp: 'CURP',
    curpPlaceholder: '18 symbols',
    email: 'Email',
    emailPlaceholder: 'example@mail.com',
    requiredField: 'Required field',
    patternError: 'Invalid value',
  },
  result: {
    headerProcessingProblem: 'Application processing',
    buttonHome: 'Home',
    buttonUpdate: 'Update',
    buttonLogIn: 'Log in',
    buttonSupport: 'Support',
    ok: 'Ok',
    oops: 'Oops!',
    operationNotCompleted: 'The operation could not be completed',
    operationNotCompletedDescription:
      'Oops, something went wrong. Please try again. If you continue to experience this issue, get in touch with our support team.',
    notCalculatorDate: 'Something went wrong. We need to fix some stuff. Comeback later',
    somethingWentWrong: 'Something went wrong. Try again later',
    buttonRetry: 'Retry',
    operationWait: 'Loan application is under review',
    operationWaitDescription: 'Please wait',
    successfully: 'Successfully',
    passSuccessfullyDesc: 'Your password has been changed successfully!',
    passSuccessfullyRecoveredDesc: 'Your password has been successfully recovered!',
    withinAFewMinutes: 'Within a few minutes, the money will be credited to your account.',
    youHaveSuccessfullyRegistered:
      'You have successfully registered and your Loan application is in processing',
    error: 'Error',
    passwordBlacklist:
      'Password is on the list of most frequently used passwords. Please create another password',
    passwordContains: 'Password must not include login. Please create another password',
    loanApplicationProcessInterruption: 'Loan Application Process Interruption',
    loanApplicationProcessHanding: 'Loan Application Process Handing',
    operationInProgress: 'Operation in progress. Check the result later',
    congratulations: 'Congratulations!',
    referToYourAccount: 'Please refer to your account for details',
    processingRequestTakeSomeTime: 'Processing the request may take some time',
    applicationInProcessing: 'Application in processing',
    pleaseWaitSituationDoesNotChange:
      'Please wait. If the situation does not change, then write to technical support',
    applicationNotCreated: 'Application not created',
    toCreateNewApplicationCancelPrevious:
      'To create a new application, you must cancel the previous application or repay the loan',
    failedCreateApplication: 'Failed to create a loan application',
  },
  occupation: {
    title: 'Occupation',
  },
  statesMunicipalities: {
    titleStates: 'State',
    titleMunicipalities: 'Municipalities',
    placeOfBirth: 'Place of Birth',
    ne: 'Nacido en el Extranjero',
  },
  createPassword: {
    title: 'Password',
    subtitle: 'Please create your password',
    description: 'This password is required to access your account',
    password: 'Password',
    confirmPassword: 'Confirm password',
    button: 'Create',
    newPasswordThatDoNotMatch: 'Passwords do not match',
    newPasswordLengthError: 'Minimum 8 characters, mixed case and numbers',
    newPasswordMaxLengthError: 'Maximum 16 characters, mixed case and numbers',
    requiredPassword: 'Please enter your password!',
    requiredConfirmPassword: 'Please confirm your password!',
  },
  loanApplication: {
    title: 'Loan application',
    confirmButton: 'Confirm',
    summary: 'Summary',
    document: 'Document',
    contactInformation: 'Contact information',
    payoutInformation: 'Payout information',
    requestedAmount: 'Requested amount',
    repaymentAmount: 'Repayment amount',
    term: 'Term',
    firstName: 'First name',
    fathersName: 'Father’s last name',
    mothersName: 'Mother’s last name',
    gender: 'Gender',
    dateOfBirth: 'Date of Birth',
    stateOfBirth: 'Place of Birth',
    claveDeElector: 'Clave De Elector',
    curp: 'CURP',
    rfc: 'RFC',
    phoneNumber: 'Phone number',
    email: 'Email',
    requiredField: 'Required field',
    clickingContinue: 'By clicking Continue you agree to request your',
    linkText: 'credit history',
    destinationAccount: 'CLABE',
    bankAccount: 'Bank account',
    paymentMethod: 'Payment method',
  },
  documentUpload: {
    title: 'Scan Your INE Card',
    subTitle:
      'To continue applying for a loan, please scan the INE card to identify your identity.',
    button: 'Upload',
  },
  selfieUpload: {
    title: 'Selfie check',
  },
  profilePage: {
    title: 'Profile',
    docTitle: 'Documents',
    docDesc: 'List of customer’s documents',
    contactTitle: 'Contact Data',
    contactDesc: 'Phone number, email',
    settingTitle: 'Settings',
    settingDesc: 'Password, Face ID',
    logOutTitle: 'Log out',
    logOutDesc: 'Data will be saved',
    dialogApplicationTitle: 'Log out',
    dialogApplicationDescription: 'Are you sure you want to log out?',
    dialogCancelButton: 'Cancel',
    dialogConfirmButton: 'Log out',
    dialogDeleteButton: 'Delete',
    deleteProfileTitle: 'Delete Profile & Data',
    deleteProfileDescription: 'Permanently delete all data',
    deleteProfileModalTitle: 'Want to delete your profile & data?',
    deleteProfileModalDescription:
      'Sending request to delete all of your personal data and profile, you won’t receive any kind of out special offers and promo’s. Also due to regulation we can’t delete any records from regulation sources such as credit bureau and etc. However if you decide to delete your profile press “Delete” button. And we will proceed your request as soon as possible and send you the notification',
  },
  loan: {
    title: 'Loan',
  },
  application: {
    titlePage: 'History',
    title: 'Application',
    cancelButton: 'Cancel',
    continueButton: 'Continue',
    cancelLoanButton: 'Cancel loan application',
    noHistoryDesc:
      "There's nothing here yet, but your history will appear after you submit a request.",
    errorHistoryDesc: 'Failed to load history. Try again later',
    draft: 'Draft',
    onReview: 'On review',
    approved: 'Approved',
    rejected: 'Rejected',
    cancelled: 'Cancelled',
    expired: 'Expired',
    issued: 'Issued',
    active: 'Active',
    overdue: 'Overdue',
    paid: 'Paid',
    closed: 'Closed',
    nextPayment: 'Next payment',
    totalPaid: 'Total paid amount',
    creationDate: 'Creation date',
    nextPaymentDate: 'Date',
    closedDate: 'Closing date',
    repaymentDate: 'Repayment date',
    requestedAmount: 'Requested amount',
    term: 'Term',
    availableUntil: 'Available until',
    paymentDetails: 'Payment Details',
  },
  id: {
    titlePersonal: 'Personal info',
    titleID: 'ID',
    titleAddress: 'Address',
    buttonTitle: 'Confirm',
    firstName: 'First name and second name',
    fathersName: 'Father’s last name',
    mothersName: 'Mather’s last name (optional)',
    mothersNamePlaceholder: 'Mother’s last name',
    gender: 'Gender',
    dateOfBirth: 'Date of Birth',
    dateOfBirthPlaceholder: 'Select',
    claveElector: 'Clave De Elector',
    claveElectorPlaceholder: '18 symbols',
    curp: 'CURP',
    curpPlaceholder: '18 symbols',
    rfc: 'RFC',
    rfcPlaceholder: '13 symbols',
    occupation: 'Occupation',
    email: 'Email',
    occupationPlaceholder: 'Select',
    placeOfBirth: 'Place of Birth',
    state: 'State',
    municipality: 'Municipality',
    city: 'City',
    region: 'Suburb',
    street: 'Street',
    postcode: 'Postcode',
    requiredField: 'required field',
    rfcLengthError: 'contains 13 characters',
    emailError: 'example@mail.com',
    cancelButton: 'Cancel',
    okButton: 'Sure',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
  introScreen: {
    title: 'Verification',
    buttonTitle: 'Start',
    buttonTitleNoNubariumStepOne: 'Imitation nubarium, step 1',
    buttonTitleNoNubariumStepTwo: 'Imitation nubarium, step 2',
    buttonTitleNoJumio: 'Imitation jumio',
    subtitle: 'To confirm your identity please do the following',
    takePhotoIne: 'Take a photo of your INE',
    takePhotoFace: 'Take a photo of your face',
    verifyData: 'Verify your data',
  },
  infoBlock: {
    days: 'days',
  },
  processingApp: {
    title: 'Processing your application',
  },
  yourApplication: {
    titleApproved: 'Your loan is approved!',
    titleLoading: 'Processing your application',
    summary: 'Summary',
    docSubtitle: 'Here are you legal documents',
    holdOn: 'Please hold on',
    textContent1:
      'We are currently processing your loan application and this process may take between 1 minute and 1 hour.',
    textContent2:
      'To ensure that the application is processed without interruption please do not close this window. We appreciate your patience as we work hard to speed up this process.',
    requestedAmount: 'Requested amount',
    approvedAmount: 'Approved amount',
    term: 'Term',
    repaymentAmount: 'Repayment amount',
    repaymentDate: 'Repayment date',
    paymentSchedule: 'Payment schedule',
    loanArgeement: 'Loan argeement',
    needConfirmText: 'You need to confirm your loan agreement with the SMS code',
    iConfirmText: 'I confirm I have read Loan agreement and ready to sign it',
    sendButton: 'Get SMS code',
    cancelButton: 'Cancel',
    geolocationError: 'Geolocation error',
    byClickingGetSms:
      'By clicking Get SMS code you confirm you\u00A0have read Loan agreement and ready to\u00A0sign it',
    dialogApplicationTitle: 'Cancel Application',
    dialogApplicationDescription: 'Are you sure you want to cancel your loan application?',
    dialogConfirmButton: 'Yes, Cancel',
    dialogCancelButton: 'No, Continue',
  },
  requestStatus: {
    expired: {
      title: 'Expired',
      description: 'Loan application is expired. Would you like to create a new one?',
    },
    cancelled: {
      title: 'Cancelled',
      description: 'Loan application is cancelled. Would you like to create a new one?',
    },
    rejected: {
      title: 'Rejected',
      description: "Ooops.. Unfortunately we cant't approve this loan, try one of our partners!.",
      descriptionRejectedNoPartners:
        'Loan application is rejected. You can change loan parameters and try again.',
    },
    moratorium: {
      title: 'Oops!',
      description:
        'Sorry but at the moment we cannot provide you with a loan. Comeback soon and try again 🙂',
    },
    buttonText: 'Home',
  },
  duplicatePhoneNumber: {
    title: 'This phone number is already registered',
    subTitle: 'Please log in or contact support for assistance.',
  },
  loanAppDetailsPage: {
    title: 'Loan details',
  },
  destinationAccountPage: {
    destinationAccount: 'Destination account',
    buttonTitle: 'Confirm',
    depositErrorMessage: 'Incorrect account number',
    label: 'Enter deposit account (CLABE)',
    placeholder: '18 digits',
    paymentMethod: 'Payment method',
    bankAccount: 'Bank account',
  },
  loanWidget: {
    titleLoan: 'Loan number',
    titleApplication: 'Loan application',
    buttonTitle: 'Continue',
    requestedAmount: 'Requested amount',
    creationDate: 'Creation date',
    term: 'Term',
    days: 'days',
    availableUntil: 'Available until',
    altImage: 'Money',
    dialogTitle: 'Loan Application Process Interruption',
    dialogDescription: 'You are about to interrupt your loan application process. ',
    dialogCancelButton: 'Cancel',
    dialogConfirmButton: 'Discard',
  },
  screenFooter: {
    home: 'Home',
    history: 'History',
    chat: 'Chat',
    profile: 'Profile',
  },
  progressBar: {
    enterPhoneNumber: 'Let us know how to contact you and get your goals closer',
    confirmPhone: 'Confirm your phone',
    createPassword: 'Let’s be secure, create a password',
    turnYourCamera: 'Okay it’s almost done, turn your camera and lets take some picture',
    checkYourInfo: 'Almost gone, let’s check your info',
    checkYourAddress: 'One more thing, check/fill your address',
    lastOne: 'The last one, we will transfer your loan here',
    checkYourApplication: 'It’s done, let’s check your application',
    yourLoanApproved: 'Wow, your loan was approved, let’s confirm it',
    lastOneStep: 'The last one step',
  },
  personalInfo: {
    title: 'Personal information',
    mainInformation: 'Main information',
    firstName: 'First name',
    fathersName: 'Father’s last name',
    mothersName: 'Mother’s last name',
    gender: 'Gender',
    dateOfBirth: 'Date of Birth',
    placeOfBirth: 'Place of Birth',
    claveElector: 'Clave De Elector',
    curp: 'CURP',
    rfc: 'RFC',
    address: 'Address',
    postcode: 'Postcode',
    state: 'State',
    municipality: 'Municipality',
    city: 'City',
    region: 'Region',
    street: 'Street',
    payoutInformation: 'Payout information',
    destinationAccount: 'Destination account',
    bankAccount: 'Bank account',
    clabe: 'CLABE',
  },
  contactData: {
    title: 'Contact Data',
    phoneNumber: 'Phone number',
    email: 'Email',
  },
  settingsPage: {
    installed: 'Installed',
    notInstalled: 'Not installed',
    installedInstead: 'Used instead of a PIN code',
    title: 'Settings',
    password: 'Password',
    pin: 'PIN code',
    biometricAuthentication: 'Biometric authentication',
  },
  changePasswordPage: {
    title: 'Change password',
    currentPassword: 'Current password',
    currentPasswordPlaceholder: 'Enter your current password',
    subtitle: 'New password',
    createPassword: 'Create a password',
    createPasswordPlaceHolder: 'Enter your new password',
    confirmPassword: 'Confirm password',
    buttonTitle: 'Confirm',
  },
  appStatus: {
    draft: 'DRAFT',
    onReview: 'ON_REVIEW',
    approved: 'APPROVED',
    rejected: 'REJECTED',
    cancelled: 'CANCELLED',
    expired: 'EXPIRED',
  },
  gender: {
    mujer: 'Mujer',
    hombre: 'Hombre',
  },
  alertMessage: {
    errorLoginForbidden: 'You do not have access rights',
    errorOther: 'Something went wrong',
    warningContactAdmin: 'Contact your administrator to restore access',
    infoSmsSend: 'SMS code sent',
    errorLogin: 'Invalid phone number or password',
    errorSmsSend: 'SMS code not sent. Please try again later',
    errorSmsSendBlock: 'A new SMS code can be generated in xx min/sec',
    errorWrongOtp: 'Incorrect code',
    invalidPassword: 'Invalid password',
    errorCodeExpired: 'Code has expired. Please request a new code',
    errorCodeUnable: 'Unable to register. Contact technical support',
    errorCodeExceeded:
      'Invalid code. The number of attempts to enter the verification code has been exceeded. Please request a new code',
    alreadyRegistered: 'A user with this phone number is already registered',
    passwordBlacklist:
      'Password is on the list of most frequently used passwords. Create another password',
    passwordContainsUsername: 'Password must not include login. Create another password',
    passwordIncorrect: 'Minimum 8 characters, mixed case and numbers',
    badRequest: 'Bad Request (400)',
    checkValue: 'Check value',
    timeoutExceeded: 'Timeout exceeded',
    checkStatusSupport: 'Please check status application or contact support',
    incorrectDataSupport: 'Incorrect data. Contact support',
    emailNotSet: 'Email not set Must be set in Profile',
    temporaryProblems: 'Temporary problems. Please try again later',
    temporaryErrorOccurred: 'A temporary error occurred. Please try again later.',
    http400: 'Http 400',
    smsCodeSent: 'SMS code sent to',
    failedVerifySms: 'Failed to verify SMS code. Please try again later',
    incorrectAccountNumber: 'Incorrect account number',
    makeSureAccount: 'Please make sure that the account you entered belongs to you',
    invalidPhoneNumber: 'Invalid phone number',
    checkAccuracyEnteredCurp: 'Please check the accuracy of the entered CURP number',
    newPasswordMustNotMatch:
      'New password must not match any of the last three passwords. Please create another password',
    youAlreadyRequestedProfileDeletion: "You've already requested profile deletion. Please wait.",
    userWithCurpNumber: 'User with CURP number',
    alreadyExistsConnectedToPhone: 'already exists and connected to phone number',
    pleaseLoginIntoYourAccount: '. Please login into your account.',
  },
  screenAccount: {
    hello: 'Hello!',
  },
  stepBar: {
    verify: 'Verify',
    signUp: 'Sign up',
    document: 'Document',
    result: 'Result',
  },
  confirmEmail: {
    successfullyDescription:
      'Hi, You have successfully confirmed your email! More is now available to you in the Monetech app',
    errorDescription: 'Something went wrong :( Your link is no longer valid',
  },
  partnersBlock: {
    buttonTitle: 'Take me',
  },

  pdfViewer: {
    prev: 'Preview',
    next: 'Next',
    page: 'Page',
    from: 'from',
    download: 'Download PDF',
  },
  chatPage: {
    title: 'Chat',
  },
  nubariumErrorDialog: {
    title: 'Data Failed to Load',
    description:
      'We encountered an issue while loading the data. Please ensure the document is well-lit and stationary. ',
    cancelButton: 'Cancel',
    tryAgainButton: 'Try Again',
  },
  pdfDocTitles: {
    loan_agreement: 'Loan agreement',
    payment_schedule: 'Payment schedule',
  },
  documents: 'Documents',
  loanInfo: {
    title: 'Loan Payment',
    repaymentAmount: 'Repayment amount',
    interestAmount: 'Interest amount',
    commissionAmount: 'Early repay commission',
    loanAmount: 'Loan amount',
    overdue: 'Overdue charge',
    closingDate: 'Closing date',
    paidAmount: 'Paid amount',
    payDetailsStepTitle:
      'To pay loan just make a money transfer to our account by the following account details:',
    purposeStepTitle: 'Purpose of payment:',
    purposeStepText: 'Payment for the loan agreement',
    noticeStepTitle: 'Important notice:',
    repaymentSummary: 'Repayment summary:',
    noticeStepText:
      'You should carefully copy information above and not to change anything in the other case your payment should late and you go to overdue',
    repayBtn: 'Repay',
  },
  faq: {
    title: 'FAQ',
  },
  copied: 'Successfully copied!',
  recoveryPassword: {
    title: 'Password Recovery',
    titleNewPassword: 'Set a New Password',
    toResetPasswordEnterCurp: 'To reset your password, please enter your CURP number',
  },
  pin: {
    pageTitle: 'PIN code',
    subtitle: 'Create an access key to make it easier for you to sign in next time:',
    desc1: 'An access key is a secure and simple way to get back into your account',
    desc2: 'Use it with Face ID or fingerprint recognition for quick access',
    desc3: 'Your access key is safely stored',
    create: 'Create PIN code',
    edit: 'Edit PIN code',
    repeat: 'Repeat PIN code',
    disable: 'Disable PIN code',
    current: 'Current PIN code',
    passTitle: 'Password',
    passText: 'Please enter your account password to set up a PIN code',
    confirm: 'Confirm',
    cancel: 'Cancel',
    passErrRequired: 'Enter the password',
    passInvalid: 'Invalid password',
    successTitle: 'Successfully',
    pinErrMatch: 'The PIN code does not match. Try again',
    pinErr: 'Incorrect PIN code. Try again',
    successText:
      'The PIN code has been successfully set. You can now use your PIN code to quickly log into the app.',
    successTextEdit:
      'The PIN code has been successfully changed. You can now use your new PIN to quickly log into the app.',
    disableTitle: 'Disable PIN code',
    disableText: 'Are you sure you want to disable the PIN code?',
    loginByPassword: 'Login by password',
    attempts: 'more attempts left',
  },
};
