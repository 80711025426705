import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SafeArea } from 'antd-mobile';
import { Suspense } from 'react';

import { useAppSelector } from '../../hooks/use-redux-hook';
import { ErrorPage } from '../../pages/error-page';
import { errorSelector, setAppError } from '../../redux/modules/app';
import { ErrorBoundary } from '../error-boundary';
import { useBackButton } from '../../hooks/use-back-button';
import { Paths } from '../../routes/paths-enum';

// import { appUrl, env } from '../../constants/app-url';
// import { LOCAL_STORAGE } from '../../constants/local-storage';

export const Layout = () => {
  useBackButton(Paths.HOME);
  const error: string = useAppSelector(errorSelector);
  const dispatch = useDispatch();

  if (error) {
    return <ErrorPage error={error} setState={() => dispatch(setAppError(''))} />;
  }

  return (
    <Suspense>
      <ErrorBoundary>
        <div style={{ background: 'var(--linear-gradient)' }}>
          <SafeArea position='top' />
        </div>
        <Outlet />
        <div style={{ background: 'var(--linear-gradient)' }}>
          <SafeArea position='bottom' />
        </div>
      </ErrorBoundary>
    </Suspense>
  );
};
