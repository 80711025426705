import { useEffect, useState } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';

import { LOCAL_STORAGE } from '../constants/local-storage';
// import { TIMERS } from '../constants/timers';
// import { Paths } from '../routes/paths-enum';

export const usePinTimers = () => {
  const [counter, setCounter] = useState(0);
  // const navigate = useNavigate();
  // const location = useLocation();

  const handleTimers = () => {
    const refreshPinTimer = localStorage.getItem(LOCAL_STORAGE.refreshTimer);
    const refreshPinToken = localStorage.getItem(LOCAL_STORAGE.pinRefreshToken);
    const incorrectTimer = localStorage.getItem(LOCAL_STORAGE.incorrectPinTimer);
    const bioTimer = localStorage.getItem(LOCAL_STORAGE.bioRefreshTimer);
    const now = Date.now();

    if ((refreshPinToken || refreshPinTimer) && now > Number(refreshPinTimer)) {
      localStorage.removeItem(LOCAL_STORAGE.refreshTimer);
      localStorage.removeItem(LOCAL_STORAGE.pinRefreshToken);
      localStorage.removeItem(LOCAL_STORAGE.salt);
    }
    if (incorrectTimer && now > Number(incorrectTimer)) {
      localStorage.removeItem(LOCAL_STORAGE.incorrectPinTimer);
      localStorage.removeItem(LOCAL_STORAGE.incorrectPinCounter);
    }
    if (bioTimer && now > Number(bioTimer)) {
      localStorage.removeItem(LOCAL_STORAGE.biometrics);
      localStorage.removeItem(LOCAL_STORAGE.bioRefreshTimer);
    }
  };

  useEffect(() => {
    const handleStorage = () => {
      setCounter((prev) => prev + 1);
    };

    window.addEventListener('storage', handleStorage);
    const intervalId = setInterval(handleTimers, 1000);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('storage', handleStorage);
    };
  }, [counter]);

  // useEffect(() => {
  //   const lastActivityTime = sessionStorage.getItem(LOCAL_STORAGE.userLastActivityTime);
  //   const isAuth = sessionStorage.getItem(LOCAL_STORAGE.isAuthorized);
  //
  //   const isExpired =
  //     lastActivityTime && Date.now() - Number(lastActivityTime) >= TIMERS.LAST_ACTIVE;
  //
  //   if (isExpired) {
  //     localStorage.removeItem(LOCAL_STORAGE.isAuthorized);
  //     sessionStorage.removeItem(LOCAL_STORAGE.userLastActivityTime);
  //
  //     return;
  //   }
  //   if (isAuth && location.pathname === Paths.MAIN) {
  //     navigate(Paths.HOME);
  //   }
  // }, [location, navigate]);
};
