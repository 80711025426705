import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export enum Statuses {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

export type AlertStatuses = {
  type: Statuses;
  message: string;
  time?: number;
};

export type AlertErrorType = FetchBaseQueryError & {
  alert: AlertStatuses;
};

export type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export type ValidateErrorEntity<T> = {
  values: T;
  errorFields: Array<{ name: Array<string | number>; errors: string[] }>;
  outOfDate: boolean;
};

export enum RequestStatus {
  EXPIRED = 'expired',
  CANCELED = 'cancelled',
  REJECTED = 'rejected',
  SUCCESS = 'success',
  MORATORIUM = 'moratorium',
}
