import { useCallback, useEffect, useState } from 'react';

import { LOCAL_STORAGE } from '../constants/local-storage';
import { TIMERS } from '../constants/timers';
import { USER_ACTIVITY_EVENTS } from '../constants/user-activity-events';

export const useWatchUserActions = () => {
  const [isLogout, setIsLogout] = useState(false);
  const [lastUserActivityTime, setLastUserActivityTime] = useState(Date.now());

  const notifyCurrentAndOtherTabsUserWasActive = useCallback(() => {
    setLastUserActivityTime(Date.now());
    sessionStorage.setItem(LOCAL_STORAGE.userLastActivityTime, String(Date.now()));
  }, []);

  const handleStorageEvent = useCallback((event: StorageEvent) => {
    if (event.key === LOCAL_STORAGE.userLastActivityTime) {
      if (event.newValue) {
        setLastUserActivityTime(+new Date(+event.newValue));
      } else {
        setIsLogout(true);
      }
    }
  }, []);

  useEffect(() => {
    const inactivityInterval = setInterval(() => {
      const currentTime = Date.now();
      const timeDifference = currentTime - lastUserActivityTime;

      if (timeDifference > TIMERS.LAST_ACTIVE) {
        setIsLogout(true);
      }
    }, TIMERS.ONE_SECOND);

    window.addEventListener('storage', handleStorageEvent);
    USER_ACTIVITY_EVENTS.forEach((event) => {
      window.addEventListener(event, notifyCurrentAndOtherTabsUserWasActive);
    });

    return () => {
      clearInterval(inactivityInterval);
      window.removeEventListener('storage', handleStorageEvent);
      USER_ACTIVITY_EVENTS.forEach((event) => {
        window.removeEventListener(event, notifyCurrentAndOtherTabsUserWasActive);
      });
    };
  }, [handleStorageEvent, lastUserActivityTime, notifyCurrentAndOtherTabsUserWasActive]);

  return { isLogout, setIsLogout, lastUserActivityTime };
};
