export const ErrorCode = {
  TEMPORARY_PROBLEMS: 'temporary.problems',
  TIMEOUT: 'timeout',
  UNDEFINED_NEXT_STEP: 'undefined.nextstep',
  VALIDATOR_ERROR_CURP: 'validation.error.curp',
  VALIDATOR_ERROR_EMAIL: 'validation.error.email',
  VALIDATOR_ERROR_OCCUPATION: 'validation.error.occupation',
  VALIDATOR_ERROR_RFC: 'validation.error.rfc',
  VALIDATOR_ERROR_CLAVE_ELECTOR: 'validation.error.clave.elector',
  NEED_START_AGAIN: 'need.start.again',
  WRONG_PASSWORD: 'wrong.password',
  WRONG_OTP: 'wrong.otp',
  WRONG_PHONE: 'wrong.phone',
  WRONG_CURP: 'wrong.curp',
  OTP_SEND_DISABLED: 'otp.send.disabled',
  OTP_SEND_DISABLED_SPAM: 'otp.send.disabled.spam',
  ATTEMPTS_EXHAUSTED: 'attempts.exhausted',
  OTP_EXPIRED: 'otp.expired',
  PASSWORD_BLACKLIST: 'password.blacklist',
  PASSWORD_CONTAINS_USERNAME: 'password.contains.username',
  // PASSWORD_INCORRECT: 'password.incorrect',
  PASSWORD_INCORRECT: 'incorrect.value.password',
  PASSWORD_MATCHES_PREVIOUS: 'password.matches.previous',
  ERROR_REVIEW_APPLICATION: 'error.review.application',
  NO_MANDATORY_AGREEMENTS: 'no.mandatory.agreements',
  PHONE_BLACKLIST: 'phone.blacklist',
  INVALID_APPLICATION_ID: 'invalid.applicationid',
  ABSENT_EMAIL: 'absent.email',
  BLACKLIST: 'blacklist',
  PHONE_ALREADY_REGISTERED: 'phone.already.registered',
  ADMIN_BLOCKED: 'Admin blocked',
  WRONG_PASSWORD_OR_LOGIN: 'wrong.password.or.login',
  IN_PROGRESS: 'in.progress',
  ERROR_CREATING_APPLICATION: 'error.creating.application',
  DUPLICATE_REQUEST_CREATION: 'duplicate.request.creation',
  CURP_REGISTER_ANOTHER_USER: 'curp.registered.another.user',
  ACCOUNT_REGISTERED_ANOTHER_USER: 'account.registered.another.user',
  ERROR_ACCOUNT: 'error.account',
  REQUEST_ALREADY_SENT: 'request.already.sent',
};
